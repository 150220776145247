import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/MyNotes-Webapp/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "阿里云续费太贵"
    }}>{`阿里云续费太贵`}</h2>
    <hr></hr>
    <h2 {...{
      "id": ""
    }}><img alt="aliyun.png" src={require("./aliyun.png")} /></h2>
    <h2 {...{
      "id": "腾讯云特价活动"
    }}>{`腾讯云特价活动`}</h2>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://cloud.tencent.com/act/cps/redirect?redirect=1054&cps_key=b7d5283fdf5e33737f95e315ce83989e&from=console"
      }}><img alt="560.300.png" src={require("./560.300.jpg")} /></a></p>
    <p>{`最后选择了星星海SA2云服务器，用阿里云续费的价格可以买3年：`}</p>
    <h2 {...{
      "id": "-1"
    }}><img alt="txyun.png" src={require("./txyun.png")} /></h2>
    <h2 {...{
      "id": "docker-compose配置"
    }}>{`Docker-Compose配置`}</h2>
    <pre><code parentName="pre" {...{}}>{`version: '2'
services:
  webhook:
    image: nodewebhook:v1
    ports:
      - 8088:8088
    volumes:
      - ./webhook:/root
      - ./webhook/MyNotes-Webapp-webhook/runservice.sh:/docker-entrypoint-initdb.d/runservice.sh
    entrypoint:
      - /docker-entrypoint-initdb.d/runservice.sh
    working_dir: /root/MyNotes-Webapp-webhook
    command: tail -f ./deploy.log
    tty: true
    restart: always
  mysql:
    environment:
      MYSQL_ROOT_PASSWORD: "Zq&meqd%M6vo3Q"
      MYSQL_USER: 'gogs'
      MYSQL_PASS: 'Zq&meqd%M6vo3Q'
    image: "mysql:latest"
    restart: always
    volumes:
      - ./mysql/db:/var/lib/mysql
      - ./mysql/conf/my.cnf:/etc/my.cnf
      - ./mysql/init:/docker-entrypoint-initdb.d/
  bitwarden:
    image: bitwardenrs/server:latest
    volumes:
      - ./bitwarden/data:/data/
    restart: always
  shaarli:
    image: shaarli/shaarli:latest
    volumes:
      - ./shaarli/data:/var/www/shaarli/data/
    restart: always
  portainer:
    image: portainer:v1
    ports:
      - 9000:9000
    volumes:
      - /var/run/docker.sock:/var/run/docker.sock
      - ./portainer/certs:/certs/
    restart: always
  imgurl:
    image: samchenxx/imgurl:1.0.0
    volumes:
      - /imgurl/imgs:/usr/share/nginx/html/imgs
    privileged: true
    restart: always
  gogs:
    image: gogs/gogs:latest
    ports:
      - 2222:2222
    volumes:
      - ./gogs:/data
      - /gogs/.ssh:/home/git/.ssh
    restart: always
    links:
      - mysql
    depends_on:
      - mysql
  nginx:
    image: nginx:latest
    ports:
      - 4000:4000
      - 80:80
      - 443:443
      - 8000:8000
      - 8443:8443
    privileged: true
    volumes:
      - ./webhook/MyNotes-Webapp/.docz/dist/:/usr/share/nginx/html
      - ./nginx/config/nginx.conf:/etc/nginx/nginx.conf
      - ./nginx/config/conf.d/default.conf:/etc/nginx/conf.d/default.conf
      - ./nginx/logs:/var/log/nginx/
      - ./nginx/ssl:/ssl/
    restart: always
    depends_on:
      - shaarli
      - bitwarden
      - portainer
      - imgurl
      - gogs
      - webhook
`}</code></pre>
    <p>{`docker-compose up -d 执行后启动多个服务：`}</p>
    <p><a parentName="p" {...{
        "href": "http://cvm.chenxixian.cn:8088/webhook"
      }}>{`nodewebhook代码同步`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://cvm.chenxixian.cn/"
      }}>{`bitwarden密码库`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://cvm.chenxixian.cn:8000/"
      }}>{`shaarli网址库`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://cvm.chenxixian.cn:9000/"
      }}>{`portainer控制台`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://cvm.chenxixian.cn:80/"
      }}>{`gogs版本管理`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://cvm.chenxixian.cn:4000/"
      }}>{`imgurl图床`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://cvm.chenxixian.cn:8443/"
      }}>{`MyNotes站点`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      